import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

export default function FormLayout({ children }) {
  return (
    <div css={tw`h-full`}>
      {children &&
        children.map((item, index) => {
          return (
            <div css={index !== 0 && tw`mt-4`} key={index}>
              {item}
            </div>
          )
        })}
    </div>
  )
}

import React from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import Spinner from "general/components/Spinner"

const StyledButton = styled.button`
  ${tw`border bg-white rounded-sm leading-none transition duration-300`}

  ${({ size }) => {
    switch (size) {
      case "large":
        return tw`text-lg py-3 px-6 rounded-sm`
      case "slim":
        return [
          tw`text-sm py-1 px-3 rounded-sm`,
          css`
            min-height: 30px;
          `,
        ]
      default:
        return [
          tw`text-base py-2 px-6 font-medium rounded-sm`,
          css`
            min-height: 36px;
          `,
        ]
    }
  }}

  ${({ fullWidth }) => fullWidth && tw`block w-full`}

  ${({ primary, outline, yellow, plain }) => {
    if (primary) {
      if (outline) {
        return tw`bg-white text-primary-500 border-primary-600`
      }
      return tw`bg-primary-500 border-primary-600 text-white`
    }

    if (plain) {
      return [
        css`
          color: #d53879;
          background-color: #fff3f8;
          border-color: #fff3f8;
        `,
      ]
    }

    if (yellow) {
      if (outline) {
        return [
          css`
            color: #ecb043;
            border-color: #ecb043;
          `,
        ]
      }
      return [
        tw`text-white`,
        css`
          background-color: #ecb043;
        `,
      ]
    }
  }}

  ${({ disabled }) => {
    return (
      disabled &&
      css`
        opacity: 0.65;
      `
    )
  }}
`

export default function Button(props) {
  const {
    children,
    primary,
    yellow,
    onClick,
    size,
    fullWidth,
    submit,
    type,
    loading,
    disabled,
    plain,
    onKeyPress,
    outline,
  } = props

  const renderSpinnerColor = () => {
    if (primary) {
      return "white"
    }

    return "primary"
  }

  const color = renderSpinnerColor()

  return (
    <StyledButton
      onKeyPress={onKeyPress}
      primary={primary}
      plain={plain}
      yellow={yellow}
      outline={outline}
      onClick={onClick}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      submit={submit}
      type={type}
    >
      <span>{loading ? <Spinner color={color} /> : children}</span>
    </StyledButton>
  )
}

// import React from "react"
// import styled from "styled-components"
// import tw from "twin.macro"

// const Wrapper = styled.button`
//   ${tw`outline-none rounded-md border border-gray-300 bg-white text-gray-800 font-medium`}

//   ${({ primary }) => {
//     return (
//       primary && tw`bg-primary-500 border-primary-500 text-white font-medium`
//     )
//   }}

//   ${({ fullWidth }) => fullWidth && tw`w-full block`}

//   ${({ outline, primary }) => {
//     if (outline) {
//       if (primary) {
//         return tw`border border-primary-500 bg-transparent text-primary-500`
//       }

//       return tw`border bg-transparent`
//     }
//   }}

//   ${({ size }) => {
//     switch (size) {
//       case "slim":
//         return tw`py-1 px-4 text-sm rounded-sm`
//       case "large":
//         return tw`py-3 px-4`
//       default:
//         return tw`py-2 px-6`
//     }
//   }}

//   &:hover {
//     /* ${({ primary }) => primary && tw`bg-primary-500`} */
//   }
// `

// export default function Button({
//   primary,
//   children,
//   onClick,
//   fullWidth,
//   outline,
//   size,
//   disabled,
// }) {
//   return (
//     <Wrapper
//       size={size}
//       onClick={onClick}
//       primary={primary}
//       fullWidth={fullWidth}
//       outline={outline}
//       disabeld={disabled}
//     >
//       {children}
//     </Wrapper>
//   )
// }

import React from "react"
import PropTypes from "prop-types"
import styled, { css, withTheme } from "styled-components"
import SyncLoader from "react-spinners/SyncLoader"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const Loader = styled(SyncLoader).attrs({
  // color: ({ color, theme }) => (color ? color : theme.colors.primary[400]),
})``

function Spinner(props) {
  const { loading, color, theme } = props

  const getColor = () => {
    switch (color) {
      case "primary":
        return theme.colors.primary[400]
      case "white":
        return "#ffffff"
      default:
        return "#ffffff"
    }
  }

  return (
    <div>
      <Loader css={override} size={8} loading={loading} color={getColor()} />
    </div>
  )
}

export default withTheme(Spinner)

Spinner.propTypes = {
  color: PropTypes.string,
}
Spinner.defaultProps = {
  color: "white",
}

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const Heading = ({ element, children }) => (
  <h2 css={tw`text-2xl font-semibold text-primary-500 leading-snug`}>
    {" "}
    {children}
  </h2>
)
// React.createElement(
//   styled(element)`
//     ${tw`text-2xl font-bold`}
//     ${element === "h1" && tw`text-3xl font-bold`}
//     ${element === "h2" && tw`text-2xl font-semibold`}
//     ${element === "h3" && tw`text-xl font-bold`}
//     ${element === "h4" && tw`text-lg font-bold`}
//     ${element === "h5" && tw`text-base font-bold`}
//     ${element === "h6" && tw`text-sm font-bold`}
//   `,
//   {},
//   children
// )

Heading.propTypes = {
  element: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
}

Heading.defaultProps = {
  element: "h2",
}

export default Heading

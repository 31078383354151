import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { isUndefined } from "lodash"

const Wrapper = styled.label`
  ${({ hasRight }) => {
    if (hasRight) {
      return css`
        select {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        input {
          margin-right: -1px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      `
    }
  }}

  ${({ hasLeft }) => {
    if (hasLeft) {
      return css`
        select {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        input {
          margin-left: -1px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      `
    }
  }}
`

const Overlay = styled.div`
  ${tw`absolute inline-flex flex-row -mx-2`}
  right: 0.5em;
  top: 50%;
`

const Count = styled.div`
  ${tw`mx-2 h-6 -mt-3 text-gray-500 text-sm inline-block leading-none bg-white pl-1`}

  span {
    ${tw`leading-6`}
  }
`

const ButtonClear = styled.button`
  ${tw` w-6 h-6 -mt-3 outline-none focus:outline-none mx-2`}

  svg {
    fill: ${({ theme }) => theme.colors.gray[500]};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary[500]};
    }
  }
`

const TextField = React.forwardRef((props, ref) => {
  const {
    pattern,
    required,
    type,
    name,
    value,
    defaultValue,
    onChange,
    onFocus,
    label,
    maxLength,
    placeholder,
    error,
    connectedLeft,
    connectedRight,
    helpText,
    clearButton,
    onClearButtonClick,
    disabled,
    showCharacterCount,
    multiline,
    disabledEnter,
  } = props

  const renderTextCount = () => {
    if (!isUndefined(value)) {
      return maxLength - value.length < 0 ? 0 : maxLength - value.length
    }
    return 0
  }

  const handleKeyPress = e => {
    if (disabledEnter && e.key === "Enter") e.preventDefault()
  }

  return (
    <Wrapper
      css={tw`block`}
      hasLeft={!isUndefined(connectedLeft)}
      hasRight={!isUndefined(connectedRight)}
    >
      {label && (
        <span css={tw`block text-base text-gray-900 font-medium mb-2`}>
          {label}
        </span>
      )}
      <div
        css={[
          tw`flex flex-row relative bg-white`,
          !isUndefined(connectedLeft) || !isUndefined(connectedRight)
            ? tw`items-stretch`
            : tw` items-center`,
        ]}
      >
        <div
          css={[
            css`
              flex: 0 0 auto;
            `,
          ]}
        >
          {connectedLeft}
        </div>
        {multiline ? (
          <textarea
            ref={ref}
            css={[
              tw`form-textarea block w-full`,
              css`
                height: ${1.5 * multiline + 1}em;
                resize: none;
              `,
            ]}
            type={type}
            name={name}
            value={value}
            defaultValue={defaultValue}
            maxLength={maxLength}
            onFocus={onFocus}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <input
            pattern={pattern}
            required={required}
            key={name}
            css={[
              tw`relative block w-full outline-none focus:outline-none py-2 form-input`,
              error && tw`border-red-500`,
            ]}
            ref={ref}
            type={type}
            name={name}
            value={value}
            defaultValue={defaultValue}
            maxLength={maxLength}
            onFocus={onFocus}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
        {connectedRight && (
          <div
            className="flex-grow"
            // css={[
            //   css`
            //     flex: 0 0 auto;
            //   `,
            // ]}
          >
            {connectedRight}
          </div>
        )}
        <Overlay>
          {showCharacterCount && maxLength && (
            <Count>
              <span>{renderTextCount()}</span>/<span>{maxLength}</span>
            </Count>
          )}
          {clearButton && (
            <ButtonClear type="button" onClick={onClearButtonClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </ButtonClear>
          )}
        </Overlay>
      </div>
      {error && <span css={tw`block mt-1 text-red-500 text-sm`}>{error}</span>}

      {helpText && (
        <div className="text-gray-600 leading-tight mt-1 block text-sm">
          {helpText}
        </div>
      )}
    </Wrapper>
  )
})

TextField.propTypes = {
  type: PropTypes.string,
}

TextField.defaultProps = {
  type: "text",
}

export default TextField
